.checkBox {
  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  input[type='checkbox'] + span:before {
    background-color: var(--grey200);
    cursor: pointer;
    content: '';
    border-radius: 4px;
    display: inline-block;
    width: 18px;
    height: 18px;
    vertical-align: -2px;
    margin-right: 12px;
  }

  input[type='checkbox']:checked + span:before {
    background-image: url('../icons/svgs/checked.svg');
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 2px;
    background-color: var(--orange);
    color: var(--white);
  }

  input[type='checkbox']:focus + span:before,
  input[type='checkbox']:not(:disabled) + span:hover:before {
    box-shadow: 0px 0px 0px 2px var(--yellow);
    outline-color: transparent;
    outline-width: 2px;
    outline-style: dotted;
  }

  input[type='checkbox']:disabled + span {
    cursor: default;
    color: var(--black);
    opacity: 0.5;
  }
}
