.main {
  width: 100%;
  height: fit-content;
  padding: 0 20px 20px 20px;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  border-radius: 20px;

  h3 {
    font-size: 16px;
    font-weight: 700;
    color: var(--black);
    margin: 0 10px;
    padding: 16px 0;
  }

  .sCategory {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-top: 1px solid var(--grey200);

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 0;
      margin: 0 10px;
      cursor: pointer;

      span {
        color: inherit;
        font-weight: 600;
        font-size: 14px;
      }
    }

    .show {
      display: flex;
    }

    .hide {
      display: none;
    }
  }
}
