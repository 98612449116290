.main {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  border-radius: 20px;
  padding: 20px;

  h3 {
    font-weight: 700;
    font-size: 16px;
    color: var(--black);
    padding: 0 0 15px 10px;
    border-bottom: 1px solid var(--grey200);
  }

  .brands {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    .brand {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 14px;

      span {
        color: var(--grey400);
        font-weight: 600;
        font-size: 14px;
      }
    }
  }
}
