@import '/src/resize.scss';

.carousel {
  position: relative;
  border: 2px solid var(--yellow);
  border-radius: var(--r2);
  margin: 30px 0 0;
  background-color: var(--grey100);
  padding: 0 20px;

  @include for-size(mobile) {
    padding: 0 10px;
  }

  :global(.rec-slider-container) {
    margin: 0;
  }

  :global(.rec-item-wrapper) {
    overflow: visible !important;
    padding: 30px 10px !important;
  }

  .arrowLeft,
  .arrowRight {
    top: 50%;
    transform: translateY(-50%);
    z-index: 99;
    background-color: var(--white);
    border: 2px solid var(--yellow);
    color: var(--orange);
    position: absolute;
    height: 40px;
    width: 40px;
    border-radius: var(--r9);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;

    svg {
      width: 13px;
      height: 13px;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: var(--orange);
      color: var(--white);

      path {
        fill: var(--white);
      }
    }

    &:disabled {
      color: var(--grey300);

      &:hover,
      &:focus,
      &:active {
        color: var(--grey100);
      }
    }

    &.arrowRight {
      right: -20px;

      svg {
        transform: rotate(-90deg);
      }
    }

    &.arrowLeft {
      left: -20px;

      svg {
        transform: rotate(90deg);
      }
    }
  }
}
