@import '/src/resize.scss';

.nav {
  background-color: var(--white);

  .categoriesBar {
    background-color: var(--blue);

    @include for-size(mobile, tablet) {
      display: none;
    }
  }

  .topBar {
    background-color: var(--grey100);
    padding: 12px 0;

    .spaced {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include for-size(mobile) {
        align-items: center;
      }

      .link {
        margin-right: 18px;

        @include for-size(mobile) {
          display: none;
        }
      }

      .rightTopBar {
        display: flex;
        align-items: center;
        gap: 18px;

        a {
          margin-right: 0;
        }

        @include for-size(mobile) {
          display: none;
        }
      }

      .helpLink {
        .helpText {
          @include for-size(mobile) {
            display: none;
          }
        }
      }

      .mobileMenuLink,
      a,
      p {
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: var(--grey400);
        text-decoration: underline transparent;
      }

      .mobileMenuLink {
        display: none;
        gap: 10px;
        align-items: center;

        @include for-size(mobile, tablet) {
          display: flex;
        }

        p {
          font-size: 16px;
          font-weight: 700;
          letter-spacing: 1px;
        }
      }

      a {
        transition: all 0.3s ease-in-out;

        &:hover {
          text-decoration: underline var(--orange);
        }
      }
    }
  }

  .mainNav {
    padding: 12px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--white);

    @include for-size(mobile, tablet) {
      display: grid;
      padding: 7px 0;
      box-shadow: var(--bs1);
      border-bottom: 1px solid var(--grey200);
      border-top: 1px solid var(--grey200);

      .logo {
        width: 130px;
      }
    }

    @include for-size(mobile) {
      grid-template-columns: 3fr 2fr 1fr;
      gap: 15px;
    }

    @include for-size(tablet) {
      grid-template-columns: 5fr auto 0.3fr;
      gap: 40px;
    }

    &.scrolled {
      position: fixed;
      top: -85px;
      z-index: 999;
      width: 100vw;
      max-width: 100%;
      box-shadow: var(--bs2);
      padding-left: calc(12px + (100vw - var(--maxWidth)) / 2);
      padding-right: calc(12px + (100vw - var(--maxWidth)) / 2);
      opacity: 0;
      animation: 0.5s animateNavIn ease-in-out forwards;
      border-bottom: 1px solid var(--grey100);

      @include for-size(mobile, tablet) {
        padding-left: 12px;
        padding-right: 12px;
      }
    }

    .rightLinks {
      display: flex;
      align-items: center;
      gap: 39px;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      color: var(--black);

      @include for-size(mobile, tablet) {
        width: fit-content;
      }

      .account {
        @include for-size(mobile, tablet) {
          display: none;
        }
      }

      a {
        display: inline-flex;
        align-items: center;
        gap: 13px;
      }

      .cart {
        position: relative;

        .cartItemsCount {
          position: absolute;
          border: 2px solid var(--white);
          background-color: var(--orange);
          aspect-ratio: 1/1;
          display: block;
          text-align: center;
          font-weight: 700;
          font-size: 15px;
          line-height: 17px;
          padding: 5px 8px;
          top: -20px;
          left: 10px;
          color: var(--white);
          border-radius: var(--r9);
        }
      }
    }

    .search {
      position: relative;

      @include for-size(mobile, tablet) {
        display: none;
      }

      .searchInner {
        border: 1px solid var(--grey200);
        border-radius: var(--r9);
        height: 46px;
        display: flex;
        align-items: center;
        overflow: hidden;

        input {
          border: none;
          padding: 0 23px;
          color: var(--grey400);
          line-height: 46px;
          width: 316px;
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
        }

        .categories {
          border-left: 1px solid var(--grey200);

          button {
            font-weight: 600;
            font-size: 13px;
            line-height: 16px;
            color: var(--black);

            svg {
              margin-left: 22px;
            }
          }
        }

        label {
          display: inline-flex;
          border-radius: var(--r9);
          height: 42px;
          width: 42px;
          background-color: var(--grey200);
          align-items: center;
          justify-content: center;
          margin: 2px 2px 2px 0;
          transition: all 0.3s ease-in-out;

          path {
            transition: all 0.3s ease-in-out;
          }

          &:hover {
            background-color: var(--blue);

            path {
              stroke: var(--yellow);
            }
          }
        }
      }

      .categoriesOpen {
        position: absolute;
        right: 0;
        background-color: var(--white);
        padding: 10px 24px 24px;
        min-width: 214px;
        box-shadow: var(--bs2);
        border-bottom-left-radius: var(--r2);
        border-bottom-right-radius: var(--r2);
        border-top-right-radius: var(--r2);
        transform: scaleY(0);
        transform-origin: top;
        animation: 0.3s height linear forwards;
        overflow: hidden;
        z-index: 99;

        .category {
          margin: 5px 0 0 -200px;
          animation: 0.6s leftCategory var(--easeInOutExpo) forwards;
          display: flex;
          align-items: center;

          input {
            display: inline-block;
            margin-right: 10px;
          }

          label {
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
          }
        }
      }
    }

    .mobileSearch {
      display: none;
      border-radius: var(--r9);
      height: 42px;
      width: 42px;
      background-color: var(--grey200);
      align-items: center;
      justify-content: center;
      margin: 2px 2px 2px 0;
      transition: all 0.3s ease-in-out;

      @include for-size(mobile, tablet) {
        display: inline-flex;
      }

      path {
        transition: all 0.3s ease-in-out;
      }

      &:hover {
        background-color: var(--blue);

        path {
          stroke: var(--yellow);
        }
      }
    }
  }

  .categories {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;

    .category {
      display: flex;
      gap: 20px;
      transition: all 0.3s ease-in-out;

      p {
        color: var(--white);
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
      }

      path {
        stroke: var(--white);
      }

      &:hover {
        p {
          color: var(--yellow);
        }

        path {
          stroke: var(--yellow);
        }
      }
    }
  }

  .spaced,
  .mainNav,
  .categories {
    max-width: var(--maxWidth);
    margin-left: auto;
    margin-right: auto;
    padding-left: 24px;
    padding-right: 24px;
  }
}

@keyframes height {
  to {
    transform: scaleY(1);
  }
}

@keyframes leftCategory {
  to {
    margin-left: 0;
  }
}

@keyframes animateNavIn {
  to {
    opacity: 1;
    top: 0;
  }
}
