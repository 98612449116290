.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 10px 10px 10px;
  gap: 14px;

  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;

    input {
      background-color: var(--grey200);
      cursor: pointer;
      border-radius: 4px;
      width: 18px;
      height: 18px;
    }

    span {
      color: var(--grey400);
      font-weight: 600;
      font-size: 14px;
    }
  }
}
