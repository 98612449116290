.items {
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 24px;

  button {
    width: 40px;
    height: 40px;
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--grey300);
    border-radius: 8px;
    transition: all 0.2s ease-in-out;

    &:disabled {
      cursor: not-allowed;
      background-color: var(--grey300) !important;
      border-color: var(--grey300) !important;

      svg {
        path {
          fill: var(--white);
          stroke: var(--white);
        }
      }
    }

    &:hover {
      background-color: var(--orange);
      border-color: var(--orange);

      svg {
        path {
          fill: var(--white);
          stroke: var(--white);
        }
      }
    }
  }

  select {
    width: 80px;
    height: 54px;
    padding-left: 15px;
    border: 1px solid var(--grey300);
    border-radius: 8px;
    align-items: center;
  }

  svg {
    width: 13px;
    height: 13px;
  }
}

@media (max-width: 641px) {
  .items {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: 640px) and (max-width: 1007px) {
  .items {
    align-items: center;
    justify-content: center;
  }
}
