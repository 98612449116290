@import '/src/resize.scss';

.wrapper {
  --padding: 9px;
  --childR: calc(var(--r2) - var(--padding));
  --factor: calc((12 * var(--childR)) / 100);

  padding: var(--padding);
  background: var(--white);
  box-shadow: var(--bs1);
  border-radius: var(--r2);
  transition: all 0.3s ease-in-out;
  width: fit-content;
  width: 275px;

  @include for-size(tablet) {
    width: 240px;
  }

  &:hover {
    box-shadow: var(--bs2);
    transform: scale(1.02);

    &:hover {
      button {
        width: 86px;

        .cartTag {
          opacity: 1;
        }
      }
    }
  }

  h3 {
    margin: 17px 10px 7px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    text-decoration-line: underline;
    text-decoration-color: transparent;
    transition: all 0.3s ease-in-out;

    &:hover {
      text-decoration-color: var(--yellow);
    }
  }

  .location {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: var(--grey400);
    text-transform: uppercase;
    margin: 0 10px;
  }

  img {
    width: 100%;
    height: 230px;
    object-fit: cover;
    border-radius: calc(var(--childR) + var(--factor));
  }

  .top {
    position: relative;

    .discount {
      position: absolute;
      margin: 5px 5px;
      top: 0;
      right: 0;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: var(--yellow);

      span {
        color: var(--blue);
        font-weight: 700;
        font-size: 15px;
      }
    }
  }

  .bottom {
    margin-top: 11px;
    margin: 11px 10px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .price {
      display: flex;
      flex-direction: column;

      span {
        color: var(--black);
        font-size: 18px;
        font-weight: 700;
      }

      .strikeThrough {
        color: var(--grey400);
        font-size: 14px;
        font-weight: 600;
        text-decoration: line-through;
      }
    }

    button {
      background: #fd7400;
      height: 36px;
      width: 36px;
      border-radius: var(--r9);
      color: var(--white);
      line-height: 0;
      transition: all 0.3s ease-in-out;
      position: relative;

      &:hover {
        background: var(--blue);
      }

      svg {
        position: absolute;
        right: 11px;
        top: 50%;
        transform: translateY(-50%);
      }

      .cartTag {
        position: absolute;
        left: 11px;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
        font-weight: 700;
        font-size: 15px;
        line-height: 19px;
        transition: all 0.1s ease-in-out;
        transition-delay: 0.1s;
      }
    }

    p {
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      color: var(--black);
    }
  }
}
