@mixin for-size($size, $sizeAlt: _) {
  @if $size == desktop or $sizeAlt == desktop {
    @media (max-width: 2560px) {
      @content;
    }
  } @else if $size == desktopSm or $sizeAlt == desktopSm {
    @media (max-width: 1440px) {
      @content;
    }
  } @else if $size == tablet or $sizeAlt == tablet {
    @media (max-width: 768px) {
      @content;
    }
  } @else if $size == mobile or $sizeAlt == mobile {
    @media (max-width: 425px) {
      @content;
    }
  } @else if $size == mobileSm or $sizeAlt == mobileSm {
    @media (max-width: 375px) {
      @content;
    }
  }
}
