:root {
  --black: #191a1f;
  --white: #ffffff;

  --blue: #1f2762;
  --orange: #fd7400;
  --yellow: #ffeb3b;
  --green: #219653;
  --red: #f55a5a;

  --cream: #feefe2;
  --cream100: #fdf9dd;

  --grey100: #f7f7f9;
  --grey200: #e8e8ef;
  --grey300: #a6a8b4;
  --grey400: #757786;
  --grey500: #606167;

  // Widths
  --maxWidth: 1248px;

  // Border Radius
  --r1: 8px;
  --r2: 20px;
  --r9: 1000px;

  // Box Shadows
  --bs1: 0px 0px 4px rgba(0, 0, 0, 0.04);
  --bs2: 0px 0px 30px rgba(0, 0, 0, 0.1);

  // Font Families
  --inter: 'Inter', sans-serif;
  --jakarta: 'Plus Jakarta Sans', sans-serif;

  // easing
  --easeInOutExpo: cubic-bezier(0.87, 0, 0.13, 1);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: var(--grey100);
  color: var(--black);
  font-family: var(--jakarta);
  font-display: swap;
  max-width: 100vw;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: var(--black);
}

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
}

input,
textarea,
select {
  outline: none;
  font-family: var(--jakarta);
  border: none;
}

textarea {
  resize: vertical;
}

label {
  cursor: pointer;
}

.bodyContent {
  max-width: var(--maxWidth);
  margin: 34px auto 90px;
  padding-left: 24px;
  padding-right: 24px;
}

::selection {
  color: var(--white);
  background: var(--orange);
}

::-moz-selection {
  color: var(--white);
  background: var(--orange);
}
