@import '/src/resize.scss';

.rightPromoCards {
  display: flex;
  gap: 34px;

  @include for-size(mobile) {
    gap: 20px;
  }
  @include for-size(tablet) {
    gap: 5px;
  }

  .card {
    max-width: 275px;
    box-shadow: var(--bs1);
    border-radius: var(--r2);
    background-color: var(--white);
    overflow: hidden;
    height: fit-content;
    max-height: 350px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
      box-shadow: var(--bs2);
    }

    img {
      max-width: 100%;
      object-fit: cover;

      @include for-size(mobile) {
        max-height: 200px;
      }
    }

    .textCard {
      margin: 20px 0 34px;
      text-align: center;
      text-transform: capitalize;

      h3 {
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
      }

      p {
        font-weight: 500;
        font-size: 13px;
        line-height: 22px;
        color: var(--grey400);
      }
    }
  }
}

.leftPromoCard {
  box-shadow: var(--bs1);
  border-radius: var(--r2);
  background-color: var(--white);
  overflow: hidden;
  height: fit-content;
  max-height: 350px;
  display: flex;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: var(--bs2);
  }

  @include for-size(mobile) {
    max-height: 300px;
  }

  .textCard {
    padding: 72px 64px;
    z-index: 1;
    max-width: 335px;

    @include for-size(mobile) {
      max-width: 200px;
      padding: 52px 24px;
    }

    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
    }

    h2 {
      font-weight: 700;
      font-size: 34px;
      line-height: 42px;
      margin-top: 7px;

      @include for-size(mobile) {
        font-size: 24px;
      }
    }

    button {
      padding: 14px 21px;
      color: var(--white);
      background-color: var(--orange);
      border-radius: var(--r9);
      margin-top: 27px;
    }

    .leftPromo {
      position: absolute;
      top: -30px;
      left: -72px;
      z-index: -1;
    }
  }

  div {
    @include for-size(mobile) {
      z-index: 99;
    }
  }
}
