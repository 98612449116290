.wrapper {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;

  img {
    transform: scale(0);
    opacity: 0;
    transition: all 0.3s ease-in-out;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    display: block;

    &.loaded {
      opacity: 1;
      transform: scale(1);
    }
  }

  .placeholder {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    transition: opacity 0.3s ease-in-out;
    display: flex;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    gap: 5px;
    overflow: hidden;
    padding: 20px;
    transform: scale(1);
    opacity: 1;
    transition: all 0.3s ease-in-out;
    justify-content: center;
    align-items: center;

    &.loaded {
      opacity: 0;
      transform: scale(0);
    }

    .dotRow {
      .dot {
        background-color: var(--orange);
        width: 10px;
        height: 10px;
        margin-bottom: 5px;
        border-radius: var(--r9);

        &.animate {
          animation: toBlue 2s ease-in-out infinite;
        }

        &:nth-child(even) {
          background-color: var(--blue);

          &.animate {
            animation: toOrange 2s ease-in-out infinite;
          }
        }
      }
    }
  }
}

@keyframes toBlue {
  0% {
    background-color: var(--orange);
  }

  40% {
    background-color: var(--blue);
  }

  50% {
    background-color: var(--yellow);
  }

  60% {
    background-color: var(--orange);
  }

  100% {
    background-color: var(--blue);
  }
}

@keyframes toOrange {
  0% {
    background-color: var(--blue);
  }

  40% {
    background-color: var(--orange);
  }

  50% {
    background-color: var(--yellow);
  }

  60% {
    background-color: var(--blue);
  }

  100% {
    background-color: var(--orange);
  }
}
