@import '/src/resize.scss';

.home {
  .promos {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 34px;

    @include for-size(mobile) {
      grid-template-columns: 1fr;
    }
    @include for-size(tablet) {
      gap: 5px;
    }
  }
}
