.wrapper {
  position: relative;

  select {
    width: 100%;
    appearance: none;
  }

  select::-ms-expand {
    display: none;
  }

  svg {
    position: absolute;
    right: 18px;
    top: 50%;
    transform: translateY(-50%);
    width: 11px;
    height: 11px;
    pointer-events: none;
  }
}
