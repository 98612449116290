.main {
  width: fit-content;
  display: flex;
  flex-direction: column;

  .mImg {
    width: 100%;
    height: fit-content;
    position: relative;
    > * {
      transition: all 0.5s ease-in-out;
    }

    img {
      border-radius: 20px;
      border: 2px solid var(--orange);
      aspect-ratio: 1/1;
    }

    .discount {
      position: absolute;
      background-color: var(--yellow);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      top: 0;
      right: 0;
      margin: 20px;
    }
  }

  .images {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-top: 14px;
    gap: 14px;

    img {
      border-radius: 20px;
      cursor: pointer;
    }
  }
}

@media (max-width: 640px) {
  .main {
    width: 100%;

    .mImg {
      width: 100%;

      img {
        height: 100%;
        aspect-ratio: 1/1 !important;
        border-radius: 10px;
      }

      .discount {
        width: 30px;
        height: 30px;
        border-radius: 30px;
        margin: 10px;
      }
    }

    .images {
      width: 100%;
      flex-wrap: wrap;
      gap: 10px;

      img {
        max-width: 100%;
        aspect-ratio: 1/1 !important;
      }
    }
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .main {
    width: 100%;
    justify-content: center;

    .mImg {
      width: 100%;

      img {
        height: 100%;
        aspect-ratio: 1/1;
        border-radius: 15px;
      }

      .discount {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        margin: 15px;
      }
    }

    .images {
      width: 100%;
      gap: 12px;
      align-items: center;

      img {
        border-radius: 15px;
        max-width: 100%;
        aspect-ratio: 1/1 !important;
      }
    }
  }
}
