@import '/src/resize.scss';

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 51px 0;
  border-bottom: 1px solid var(--grey200);
  margin-bottom: 82px;

  @include for-size(mobile, tablet) {
    display: grid;
    grid-template-columns: repeat(7, 113px);
    overflow: scroll;
    align-items: center;
    gap: 10px;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .logo {
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
      transform: scale(1.05);

      path {
        fill: var(--black);
      }
    }
  }
}
