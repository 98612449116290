.wrapper {
  width: 100%;
  position: relative;

  button {
    width: 100%;
    border: 1px solid var(--grey200);
    min-height: 49px;
    border-radius: var(--r1);
    background-color: var(--white);
    z-index: 999;
    padding: 15px 18px;
    text-align: left;
    text-transform: capitalize;
  }

  .openMultiSelect {
    position: absolute;
    z-index: 99;
    background-color: var(--white);
    width: 100%;
    border-bottom-left-radius: var(--r1);
    border-bottom-right-radius: var(--r1);
    opacity: 0;
    top: 0;
    animation: animateIn 0.2s ease-in forwards;

    .option {
      display: flex;
      gap: 20px;
      align-items: center;
      border-bottom: 1px solid var(--grey200);
      padding: 10px 20px;

      &:last-child {
        border-bottom: none;
      }

      label {
        margin-bottom: 0;
      }
    }
  }
}

@keyframes animateIn {
  to {
    opacity: 1;
    top: 50px;
    box-shadow: var(--bs2);
  }
}
