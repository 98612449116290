.lDelete {
  width: 100%;
  min-height: 95px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  border-radius: 20px;
  padding: 20px;

  .dCategories {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .term {
      height: 26px;
      padding: 10px;
      display: flex;
      align-items: center;
      background-color: var(--grey200);
      border-radius: 15px;
      gap: 8px;

      span {
        font-size: 12px;
        font-weight: 700;
        color: var(--grey500);
      }

      button {
        border: none;
        outline: none;
      }
    }
  }

  h3 {
    width: fit-content;
    margin-top: 15px;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: var(--blue);
    cursor: pointer;
    border-bottom: 1px dotted;
  }
}
