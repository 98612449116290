.icon {
  width: 30px;
  height: 30px;
  position: relative;
  transition: 0.1s;
  cursor: pointer;
  display: inline-block;
  z-index: 99999;

  span {
    width: 6px;
    height: 6px;
    background-color: var(--blue);
    display: block;
    border-radius: 50%;
    position: absolute;
  }

  &:hover span {
    transform: scale(1.2);
    transition: 350ms cubic-bezier(0.8, 0.5, 0.2, 1.4);
  }

  span:nth-child(1) {
    left: 0;
    top: 0;
  }

  span:nth-child(2) {
    left: 12px;
    top: 0;
  }

  span:nth-child(3) {
    right: 0;
    top: 0;
  }

  span:nth-child(4) {
    left: 0;
    top: 12px;
  }

  span:nth-child(5) {
    position: absolute;
    left: 12px;
    top: 12px;
  }

  span:nth-child(6) {
    right: 0px;
    top: 12px;
  }

  span:nth-child(7) {
    left: 0px;
    bottom: 0px;
  }

  span:nth-child(8) {
    position: absolute;
    left: 12px;
    bottom: 0px;
  }

  span:nth-child(9) {
    right: 0px;
    bottom: 0px;
  }

  &.open {
    transform: rotate(180deg);
    cursor: pointer;
    transition: 0.2s cubic-bezier(0.8, 0.5, 0.2, 1.4);

    span {
      border-radius: 50%;
      transition-delay: 200ms;
      transition: 0.5s cubic-bezier(0.8, 0.5, 0.2, 1.4);
      background-color: var(--red);
    }

    span:nth-child(2) {
      left: 6px;
      top: 6px;
    }

    span:nth-child(4) {
      left: 6px;
      top: 18px;
    }

    span:nth-child(6) {
      right: 6px;
      top: 6px;
    }

    span:nth-child(8) {
      left: 18px;
      bottom: 6px;
    }
  }
}
