@import '/src/resize.scss';

.wrapper {
  margin: 90px 0 82px;
  background-color: var(--white);
  padding: 50px 50px 82px;
  border-radius: var(--r2);

  @include for-size(mobile) {
    padding: 50px 0 0;
  }

  .title {
    font-weight: 700;
    font-size: 28px;
    line-height: 38px;
    text-align: center;
  }
}
