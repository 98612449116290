@import '/src/resize.scss';

.wrapper {
  h2 {
    font-weight: 700;
    font-size: 28px;
    line-height: 38px;
    color: var(--black);
    margin: 0 auto 52px;
    width: fit-content;
  }

  .products {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 34px;

    @include for-size(mobile) {
      grid-template-columns: repeat(1, 1fr);
      place-items: center;
    }
    @include for-size(tablet) {
      grid-template-columns: repeat(2, 1fr);
      place-items: center;
      gap: 10px;
    }
  }

  .viewMore {
    display: block;
    background-color: var(--grey200);
    color: var(--blue);
    padding: 17px 30px;
    border-radius: var(--r9);
    margin: 97px auto 0;
    font-weight: 700;
    font-size: 15px;
    line-height: 19px;
    transition: all 0.3s ease;

    &:hover,
    &:focus {
      background-color: var(--orange);
      color: var(--white);
      box-shadow: var(--bs2);
      transform: scale(1.05);
    }
  }
}
