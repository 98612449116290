@import '/src/resize.scss';

.footer {
  padding: 90px 0;
  overflow: hidden;
  position: relative;
  z-index: 1;

  .footerShape {
    position: absolute;
    top: -90px;
    left: -160px;
    transform: scaleX(-1);
    z-index: -1;

    @include for-size(mobile) {
      top: auto;
      bottom: -200px;
    }

    path {
      stroke-dasharray: 1362.4;
      stroke-dashoffset: 1362.4;
      stroke: var(--yellow);
      stroke-width: 2px;
      opacity: 1;
      fill-opacity: 0.1;
    }
  }

  &:hover {
    .footerShape {
      path {
        animation: 2s draw linear forwards;
      }
    }
  }

  p {
    color: var(--white);
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    font-family: var(--inter);

    svg {
      margin-right: 12px;
      vertical-align: -2px;
    }
  }

  &::before {
    content: '';
    top: 0;
    left: 0;
    background-color: var(--blue);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
  }

  .content {
    max-width: var(--maxWidth);
    margin-left: auto;
    margin-right: auto;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    justify-content: space-between;

    @include for-size(mobile) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
    }

    .pages {
      a {
        display: block;
        margin-top: 8px;

        &:first-of-type {
          margin-top: 0;
        }
      }

      p {
        a,
        .categoryLink {
          display: inline;
        }

        @include for-size(mobile) {
          svg {
            display: none;
          }
        }
      }

      a,
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;
      }
    }

    .copyright {
      @include for-size(mobile) {
        order: 1;
        grid-column: 1/-1;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-top: 30px;
        margin-bottom: -50px;
      }

      p {
        margin-top: 26px;
      }
    }

    h3 {
      margin-bottom: 30px;
      color: var(--white);
      font-weight: 700;
      font-size: 20px;
      line-height: 19px;

      @include for-size(mobile) {
        margin: 20px 0 10px;
      }
    }

    a,
    .categoryLink {
      color: var(--white);
      transition: all 0.3s ease-in-out;
      text-decoration: underline transparent;

      &:hover {
        text-decoration: underline var(--yellow);
      }
    }

    .categories {
      @include for-size(mobile) {
        br {
          display: none;
        }
      }

      a,
      .categoryLink {
        width: 120px;
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;
        cursor: pointer;

        @include for-size(mobile, tablet) {
          width: fit-content;
          display: block;
        }

        &:nth-of-type(odd) {
          margin-right: 30px;

          @include for-size(mobile, tablet) {
            margin-right: 0;
          }
        }
      }
    }
  }
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
