.main {
  width: 100%;
  display: flex;
  flex-direction: column;

  p {
    font-size: 13px;
    line-height: 16.38px;
    color: var(--black);
    margin-bottom: 17px;

    span {
      margin-left: 3px;
      font-weight: 700;
    }
  }

  .colors {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    gap: 7px;

    .color {
      width: 54px;
      height: 54px;
      border-radius: 8px;
    }
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .main {
    width: 100%;
    justify-content: center;

    p {
      text-align: center;
      font-size: 16px;
    }

    .colors {
      justify-content: center;

      .color {
        width: 70px;
        height: 70px;
      }
    }
  }
}
