.wrapper {
  position: fixed;
  height: 100vh;
  width: 0;
  opacity: 0;
  overflow: hidden;
  background-color: var(--blue);
  top: 0;
  z-index: 9999;
  transition: all 0.3s ease-in-out;
  padding: 50px 12px 12px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  &.open {
    height: 100vh;
    width: 100vw;
    opacity: 1;

    .searchInner,
    .categories {
      animation: 0.5s opacity ease-in-out forwards;
      animation-delay: 0.4s;
    }

    .category {
      animation: 0.5s opacitySix ease-in-out forwards;
      animation-delay: 0.4s;
    }
  }

  .searchInner {
    border: 1px solid var(--grey200);
    border-radius: var(--r9);
    height: 46px;
    display: flex;
    align-items: center;
    overflow: hidden;
    background-color: var(--white);
    opacity: 0;

    input {
      border: none;
      padding: 0 23px;
      height: 46px;
      color: var(--grey400);
      line-height: 46px;
      width: calc(100% - 42px);
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
    }

    label {
      display: inline-flex;
      border-radius: var(--r9);
      height: 42px;
      width: 42px;
      background-color: var(--grey200);
      align-items: center;
      justify-content: center;
      margin: 2px 2px 2px 0;
      transition: all 0.3s ease-in-out;

      path {
        transition: all 0.3s ease-in-out;
      }

      &:hover {
        background-color: var(--blue);

        path {
          stroke: var(--yellow);
        }
      }
    }
  }

  .categories {
    position: relative;
    opacity: 0;

    button {
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: var(--white);
      width: 100%;
      border: 1px solid var(--grey200);
      border-radius: var(--r9);
      height: 46px;

      svg {
        height: 8px;
        width: 15px;
        vertical-align: -1px;
        margin-left: 20px;

        path {
          fill: var(--white);
        }
      }
    }

    .categoriesOpen {
      position: absolute;
      right: 0;
      background-color: var(--white);
      padding: 10px 24px 24px;
      width: 100%;
      box-shadow: var(--bs2);
      border-radius: var(--r2);
      transform: scaleY(0);
      transform-origin: top;
      animation: 0.3s height linear forwards;
      overflow: hidden;
      z-index: 99;
      color: var(--black);

      .category {
        margin: 20px 0 0 -200px;
        animation: 0.6s leftCategory var(--easeInOutExpo) forwards;
        display: flex;
        align-items: center;

        span {
          input {
            display: inline-block;
            height: 0;
            width: 0;
            margin-right: 10px;
          }

          span {
            display: flex;
          }
        }

        label {
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }

  .category {
    font-size: 35px;
    padding-left: 10px;
    opacity: 0;
  }
}

@keyframes height {
  to {
    transform: scaleY(1);
  }
}

@keyframes leftCategory {
  to {
    margin-left: 0;
  }
}

@keyframes opacity {
  to {
    opacity: 1;
  }
}

@keyframes opacitySix {
  to {
    opacity: 0.6;
  }
}
